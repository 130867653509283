/** @format */
import { styled } from '@mui/material/styles';

export const ImageBackdrop = styled('div')(({ theme }) => ({
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	background: '#000',
	opacity: 0.5,
	transition: theme.transitions.create('opacity'),
}));
