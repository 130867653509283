/** @format */

import * as React from 'react';

export const TermsContent = () => (
	<div>
		<p>Last modified: October 7th, 2018.</p>
		<h2 id='1-services'>1. Services</h2>
		<ul>
			<li>
				<p>
					1.1 These MUI Terms of Service (these &quot;Terms&quot;) apply to the
					features and functions provided by Functional Software, Inc.
					(&quot;MUI,&quot; &quot;our,&quot; or &quot;we&quot;) via mui.com (the
					&quot;Site&quot;) (collectively, the &quot;Services&quot;). By
					accessing or using the Site or the Services, you agree to be bound by
					these Terms. If you do not agree to these Terms, you are not allowed
					to use the Site or the Services. The &quot;Effective Date&quot; of
					these Terms is the date you first use the Site, or access any of the
					Services.
				</p>
			</li>
			<li>
				<p>
					1.2 If you are using the Site or accessing the Services in your
					capacity as an employee, consultant or agent of a company or other
					entity, you represent that you are an employee, consultant or agent of
					that company or entity, and that you have the authority to bind that
					company or entity to these Terms. For the purpose of these Terms, you
					(and, if applicable, the company or entity that you represent) will be
					referred to as &quot;Customer&quot; or &quot;you&quot;.
				</p>
			</li>
			<li>
				<p>
					1.3 MUI reserves the right to change or modify these Terms, or any of
					our other policies or guidelines, at any time upon notice to you. We
					may provide that notice in a variety of ways, including, without
					limitation, sending you an email, posting a notice on the Site, or
					posting the revised Terms on the Site and revising the date at the top
					of these Terms. Any changes or modifications will be effective after
					we provide notice that these Terms have been modified. You acknowledge
					that your continued use of the Site or any of the Services following
					such notice constitutes your acceptance of the modified Terms.
				</p>
			</li>
			<li>
				<p>
					1.4 MUI reserves the right – at any time, and without notice or
					liability to you – to modify the Site or the Services, or any part of
					them, temporarily or permanently. We may modify the Services for a
					variety of reasons, including, without limitation, for the purpose of
					providing new features, implementing new protocols, maintaining
					compatibility with emerging standards, or complying with regulatory
					requirements.
				</p>
			</li>
			<li>
				<p>
					1.5 These Terms form a binding agreement between you and MUI.
					Violation of any of the Terms below will result in the termination of
					your account(s).
				</p>
			</li>
		</ul>
		<h2 id='2-privacy'>2. Privacy</h2>
		<p>
			Please see MUI&#39; privacy policy at www.mui.com/privacy for information
			about how we collect, use, and disclose information about users of the
			Site and the Services. By using the Site and the Services, you consent to
			our collection, use, and disclosure of information as set forth in our
			privacy policy, as we may update that policy from time to time.
		</p>
		<h2 id='3-registration'>3. Registration</h2>
		<ul>
			<li>
				<p>
					3.1 In order to use many aspects of the Services, you must first
					complete the MUI registration process via the Site. During the
					registration process, you will be asked to select a package to access
					the Services (each, a &quot;Plan&quot;), which includes: (a) the
					period during which you can access the Services (the
					&quot;Subscription Period&quot;); and (b) the fee you must pay to MUI
					in exchange for your right to access the Services (the
					&quot;Subscription Fees&quot;). All such information is incorporated
					into these Terms by reference. We have several different types of paid
					Plans, as well as a free Plan, for which there are no Subscription
					Fees. One person or legal entity may not sign up for more than one
					free Plan.
				</p>
			</li>
			<li>
				<p>
					3.2 You agree: (a) to provide accurate, current and complete
					information about you as part of the registration process
					(&quot;Registration Data&quot;); (b) to maintain the security of your
					password(s); (c) to maintain and promptly update your Registration
					Data, and any other information you provide to MUI, and to keep it
					accurate, current and complete; (d) to accept all risks of
					unauthorized access to your Registration Data, and any other
					information you provide to MUI, via your account(s) or password(s);
					(e) that you are responsible for maintaining the security of your
					account and safeguarding your password(s), and (f) that you will be
					fully responsible for any activities or transactions that take place
					using your account(s) or password(s), even if you were not aware of
					them.
				</p>
			</li>
		</ul>
		<h2 id='4-access-to-services'>4. Access to services</h2>
		<p>
			Subject to your continued compliance with these Terms, MUI grants you a
			limited, non-transferable, non-exclusive, revocable right and license to:
			(i) access and use the Services and its associated documentation, solely
			for your own internal business purposes, for the Subscription Period for
			which you have paid the applicable Subscription Fees; and (ii) access and
			use any data or reports that we provide or make available to you as part
			of your access and use of the Services (collectively,
			&quot;Reports&quot;), solely in conjunction with your use of the Services.
			Reports are considered part of the applicable Services, for the purpose of
			the license granted above. You understand that MUI uses third-party
			vendors and hosting partners to provide the necessary hardware, software,
			networking, storage, and related technology required to provide the
			Services, and you agree that MUI is not and will not be liable or
			responsible for the acts or omissions of such third-party vendors or
			hosting partners.
		</p>
		<h2 id='5-restrictions'>5. Restrictions</h2>
		<p>
			Except as expressly authorized by these Terms, you may not: (a) modify,
			disclose, alter, translate or create derivative works of the Site or the
			Services; (b) license, sublicense, resell, distribute, lease, rent, lend,
			transfer, assign or otherwise dispose of the Services or any Report (or
			any components thereof); (c) offer any part of the Services (including,
			without limitation, any Report) on a timeshare or service bureau basis;
			(c) allow or permit any third party to access or use the Services; (d) use
			the Site or the Services to store or transmit any viruses, software
			routines, or other code designed to permit anyone to access in an
			unauthorized manner, disable, erase or otherwise harm software, hardware,
			or data, or to perform any other harmful actions; (e) build a competitive
			product or service, or copy any features or functions of the Site or the
			Services (including, without limitation, the look-and-feel of the Site or
			the Services); (f) interfere with or disrupt the integrity or performance
			of the Site or the Services;&nbsp;(g) disclose to any third party any
			performance information or analysis relating to the Site or the Services;
			(h) remove, alter or obscure any proprietary notices in or on the Site or
			the Services, including copyright notices; (i) use the Site or the
			Services or any product thereof for any illegal or unauthorized purpose,
			or in a manner which violates any laws or regulations in your
			jurisdiction; (j) reverse engineer, decompile, disassemble, or otherwise
			attempt to discover the source code, object code, or underlying structure,
			ideas, or algorithms that make up the Services or any software,
			documentation, or data relating to the Services, except to the limited
			extent that applicable law prohibits such a restriction; or (k) cause or
			permit any third party to do any of the foregoing.
		</p>
		<h2 id='6-content'>6. Content</h2>
		<ul>
			<li>
				<p>
					6.1 If you publish or upload data, images, code or content, or
					otherwise make (or allow any third party to make) material available
					by means of the Site or the Services (collectively,
					&quot;Content&quot;), you agree that you are entirely responsible for
					such Content, and for any harm or liability resulting from or arising
					out of that Content. Your responsibility applies whether or not the
					Content in question constitutes text, graphics, audio files, video
					files, computer software, or any other type of content, and whether or
					not you were the original creator or owner of the Content. You agree
					that you will be responsible for all Content on your account(s), even
					if placed there by third parties. By publishing or uploading Content
					to the Site or the Services, you represent and warrant that:
				</p>
			</li>
			<li>
				<p>
					a. the Content does not and will not infringe, violate or
					misappropriate the Intellectual Property Rights of any third party
					(where &quot;Intellectual Property Rights&quot; are defined as any
					patents, copyrights, moral rights, trademarks, trade secrets, or any
					other form of intellectual property rights recognized in any
					jurisdiction in the world, including applications and registrations
					for any of the foregoing);
				</p>
			</li>
			<li>
				<p>
					b. you have obtained all rights and permissions necessary to publish
					and/or use the Content in the manner in which you have published
					and/or used it;
				</p>
			</li>
			<li>
				<p>
					c. MUI&#39;s use of the Content for the purpose of providing the
					Services (including, without limitation, downloading, copying,
					processing, or creating aggregations of the Content) does not and will
					not (i) violate any applicable laws or regulations, or (ii) infringe,
					violate, or misappropriate the Intellectual Property Rights of any
					third party;
				</p>
			</li>
			<li>
				<p>
					d. you have fully complied with any third-party licenses relating to
					the Content;
				</p>
			</li>
			<li>
				<p>
					e. the Content does not contain or install any viruses, worms,
					malware, Trojan horses or other harmful or destructive code;
				</p>
			</li>
			<li>
				<p>
					f. the Content does not and will not include any: (i) &quot;personal
					health information,&quot; as defined under the Health Insurance
					Portability and Accountability Act, unless you have entered into a
					separate agreement with us relating to the processing of such data;
					(ii) government issued identification numbers, including Social
					Security numbers, drivers&#39; license numbers or other state-issued
					identification numbers; (iii) financial account information, including
					bank account numbers; (iv) payment card data, including credit card or
					debit card numbers; or (iv) &quot;sensitive&quot; personal data, as
					defined under Directive 95/46/EC of the European Parliament (&quot;EU
					Directive&quot;) and any national laws adopted pursuant to the EU
					Directive, about residents of Switzerland and any member country of
					the European Union, including racial or ethnic origin, political
					opinions, religious beliefs, trade union membership, physical or
					mental health or condition, sexual life, or the commission or alleged
					commission any crime or offense;
				</p>
			</li>
			<li>
				<p>
					g. the Content is not spam, is not randomly-generated, and does not
					contain unethical or unwanted commercial content designed to drive
					traffic to third party sites or boost the search engine rankings of
					third party sites, or for any other unlawful acts (such as phishing),
					or for misleading recipients as to the source of the material (such as
					spoofing);
				</p>
			</li>
			<li>
				<p>
					h. the Content does not contain threats or incitement to violence, and
					does not violate the privacy or publicity rights of any third party;
				</p>
			</li>
			<li>
				<p>
					i. the Content is not being advertised via unwanted electronic
					messages (such as, by way of example, spam links on newsgroups, email
					lists, other blogs and web sites, and similar unsolicited promotional
					methods);
				</p>
			</li>
			<li>
				<p>
					j. the Content is not named in a manner that misleads (or could
					mislead) third parties into thinking that you are another person or
					company (by way of example, your Content&#39;s URL or name should not
					be confusingly similar to the name of another person or entity); and
				</p>
			</li>
			<li>
				<p>
					k. you have, in the case of Content that includes computer code,
					accurately categorized and/or described the type, nature, uses and
					effects of the materials, whether requested to do so by the Services
					or otherwise.
				</p>
			</li>
			<li>
				<p>
					6.2 By submitting or uploading Content to the Services, you grant MUI
					a worldwide, royalty-free, and non-exclusive license (i) to use,
					reproduce, modify, adapt and publish that Content solely for the
					purpose of providing the Services to you; and (ii) to create
					aggregations and summaries of the Content or portions thereof and to
					use, disclose, and distribute such aggregations publicly to any third
					party in support of our business (both during the period that these
					Terms are in effect, and thereafter), provided that such aggregations
					and summaries do not directly or indirectly identify you or your
					Content. If you delete Content, MUI will use reasonable efforts to
					remove it from the Services. You acknowledge, however, that cached
					copies or other references to the Content may still be available.
				</p>
			</li>
			<li>
				<p>
					6.3 Without limiting any of your representations or warranties with
					respect to the Content, MUI has the right (but not the obligation) to
					reject or remove any Content, without liability or notice to you, that
					MUI believes, in MUI&#39; sole discretion: (i) violates these Terms or
					any MUI policy, (ii) violates or misappropriates the Intellectual
					Property Rights of any third party, or (iii) is in any way harmful or
					objectionable.
				</p>
			</li>
		</ul>
		<h2 id='7-fee-and-payment-terms-plan-upgrade-downgrade-cancellation-pricing-changes'>
			7. Fee and payment terms; Plan upgrade/downgrade/cancellation; Pricing
			changes
		</h2>
		<ul>
			<li>
				<p>
					7.1 In exchange for your rights to access the Site and use the
					Services during the Subscription Period, you agree to pay the
					applicable Subscription Fees to MUI. The Subscription Fees do not
					include taxes; you will be responsible for, and will promptly pay, all
					taxes associated with your use of the Site and the Services, other
					than taxes based on our net income. Subscription Fees are payable in
					full, in advance, in accordance with your Plan, and are non-refundable
					and non-creditable. You agree to make all payments in U.S. Dollars.
				</p>
			</li>
			<li>
				<p>
					7.2 You can cancel your account(s)/subscription(s) via the process set
					forth in the &quot;Cancel Subscription&quot; section of your Account
					Settings on the Site. An email or phone request to cancel your account
					is not considered cancellation. No refunds will be issued, unless
					expressly stated otherwise. All of your Content will be deleted from
					the Services within a reasonable time period from when you cancel your
					account/subscription. Deleted Content cannot be recovered once your
					account/subscription is cancelled.
				</p>
			</li>
			<li>
				<p>
					7.3 If you upgrade from the free Plan to any paid Plan, we will
					immediately bill you for the applicable Subscription Fees. There will
					be no refunds or credits for partial months of service,
					upgrade/downgrade refunds, or refunds for months unused with an open
					account.
				</p>
			</li>
			<li>
				<p>
					7.4 Downgrading your account(s) may cause the loss of Content,
					features, or capacity of your account(s). We do not accept any
					liability for such loss.
				</p>
			</li>
			<li>
				<p>
					7.5 Each Subscription Period will automatically renew (and we may
					automatically invoice you) for additional Subscription Periods of
					equivalent length, unless and until one party provides written notice
					to the other at least thirty (30) days prior to the expiration of the
					then-current Subscription Period that it wishes to terminate the
					subscription at the end of the then-current Subscription Period. We
					reserve the right to modify the fees for the Services at any time upon
					thirty (30) days&#39; prior notice to you, provided that the modified
					fees will not apply until the next Subscription Period.
				</p>
			</li>
			<li>
				<p>
					7.6 Interest on any late payments will accrue at the rate of 1.5% per
					month, or the highest rate permitted by law, whichever is lower, from
					the date the amount is due until the date the amount is paid in full.
					If you are late in paying us, you also agree that, in addition to our
					rights to suspend your access to the Services, terminate your
					account(s), downgrade you to a free Plan, and/or pursue any other
					rights or remedies available to us at law or in equity, you are
					responsible to reimburse us for any costs that we incur while
					attempting to collect such late payments.
				</p>
			</li>
		</ul>
		<h2 id='8-disclaimer'>8. DISCLAIMER</h2>
		<p>
			YOU ACKNOWLEDGE THAT THE SITE AND THE SERVICES ARE PROVIDED ON AN &quot;AS
			IS&quot;, &quot;AS AVAILABLE&quot; BASIS, WITHOUT WARRANTY OF ANY KIND,
			WHETHER EXPRESS OR IMPLIED, AND THAT YOUR USE OF THE SITE AND THE SERVICES
			IS AT YOUR SOLE RISK. ARGOS DOES NOT WARRANT: (I) THAT THE SITE OR THE
			SERVICES WILL MEET YOUR SPECIFIC REQUIREMENTS, (II) THAT THE SITE OR THE
			SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (III) THAT
			THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES WILL BE
			ACCURATE OR RELIABLE, (IV) THAT THE QUALITY OF ANY PRODUCTS, SERVICES,
			INFORMATION, OR OTHER MATERIAL THAT YOU PURCHASE OR OBTAIN THROUGH THE
			SITE OR THE SERVICES WILL MEET YOUR EXPECTATIONS, OR (V) THAT ANY ERRORS
			IN THE SITE OR THE SERVICES WILL BE CORRECTED. ARGOS SPECIFICALLY
			DISCLAIMS ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
			PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.
		</p>
		<h2 id='9-indemnification-obligations'>9. Indemnification obligations</h2>
		<p>
			You agree, at your sole expense, to defend, indemnify and hold MUI (and
			its directors, officers, employees, consultants and agents) harmless from
			and against any and all actual or threatened suits, actions, proceedings
			(whether at law or in equity), claims, damages, payments, deficiencies,
			fines, judgments, settlements, liabilities, losses, costs and expenses
			(including, without limitation, reasonable attorneys&#39; fees, costs,
			penalties, interest and disbursements) arising out of or relating to (i)
			your Content; (ii) your use of the Site or the Services; (iii) your
			failure to pay any taxes that you owe under these Terms; and (iv) any
			other actual or alleged breach of any of your obligations under these
			Terms (including, among other things, any actual or alleged breach of any
			of your representations or warranties as set forth herein). You will not
			settle any such claim in any manner that would require MUI to pay money or
			admit wrongdoing of any kind without our prior written consent, which we
			may withhold in our sole discretion.
		</p>
		<h2 id='10-limitation-of-liability'>10. LIMITATION OF LIABILITY</h2>
		<ul>
			<li>
				<p>
					10.1 IN NO EVENT WILL ARGOS&#39;S TOTAL, AGGREGATE LIABILITY TO YOU OR
					TO ANY THIRD PARTY ARISING OUT OF OR RELATED TO THESE TERMS OR YOUR
					USE OF (OR INABILITY TO USE) ANY PART OF THE SITE OR THE SERVICES
					EXCEED THE TOTAL AMOUNT YOU ACTUALLY PAID TO ARGOS IN SUBSCRIPTION
					FEES FOR THE SERVICES DURING THE TWELVE (12) MONTHS IMMEDIATELY PRIOR
					TO THE ACCRUAL OF THE FIRST CLAIM. MULTIPLE CLAIMS WILL NOT EXPAND
					THIS LIMITATION.
				</p>
			</li>
			<li>
				<p>
					10.2 IN NO EVENT WILL ARGOS BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR
					ANY LOSS OF PROFITS, LOSS OF USE, LOSS OF REVENUE, LOSS OF GOODWILL,
					INTERRUPTION OF BUSINESS, LOSS OF DATA, OR ANY INDIRECT, SPECIAL,
					INCIDENTAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND
					ARISING OUT OF, OR IN CONNECTION WITH THESE TERMS OR YOUR USE (OR
					INABILITY TO USE) ANY PART OF THE SITE OR THE SERVICES, WHETHER IN
					CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN IF WE HAVE BEEN
					ADVISED OR ARE OTHERWISE AWARE OF THE POSSIBILITY OF SUCH DAMAGES.
				</p>
			</li>
			<li>
				<p>
					10.3 THIS SECTION (LIMITATION OF LIABILITY) WILL BE GIVEN FULL EFFECT
					EVEN IF ANY REMEDY SPECIFIED IN THESE TERMS IS DEEMED TO HAVE FAILED
					OF ITS ESSENTIAL PURPOSE.
				</p>
			</li>
		</ul>
		<h2 id='11-ownership-reservation-of-rights'>
			11. Ownership; Reservation of rights
		</h2>
		<ul>
			<li>
				<p>
					11.1 As between the parties: (i) you own all right, title and interest
					in and to your Content; and (ii) MUI owns all right, title and
					interest in and to the Site and the Services, and all Intellectual
					Property Rights therein. The look and feel of the Site and the
					Services, including any custom graphics, button icons, and scripts are
					also the property of MUI, and you may not copy, imitate, or use them,
					in whole or in part, without MUI&#39; prior written consent. MUI
					reserves all rights not expressly granted to you in these Terms, and
					MUI does not grant any licenses to you or to any other party under
					these Terms, whether by implication, estoppel or otherwise, except as
					expressly set forth herein.
				</p>
			</li>
			<li>
				<p>
					11.2 You acknowledge that any suggestions, comments, or other feedback
					that you provide to MUI with respect to the Site, the Services, or any
					other MUI product or service (collectively, &quot;Feedback&quot;) will
					be owned by MUI, including all Intellectual Property Rights therein,
					and will be and become MUI&#39; Confidential Information (as defined
					below). You acknowledge and agree that MUI will be free to use,
					disclose, reproduce, license, and otherwise distribute and exploit the
					Feedback as MUI sees fit, without obligation or restriction of any
					kind. At our request and expense, you agree to execute documents or
					take such further actions as we may reasonably request to help us
					acquire, perfect, and maintain our rights in the Feedback.
				</p>
			</li>
		</ul>
		<h2 id='12-term-termination-and-effect-of-termination'>
			12 Term, termination, and effect of termination
		</h2>
		<ul>
			<li>
				<p>
					12.1 These Terms will apply to you starting on the Effective Date, and
					will continue for as long as you are accessing or using the Site or
					the Services.
				</p>
			</li>
			<li>
				<p>
					12.2 MUI, in its sole discretion, has the right to suspend your
					ability to access the Services, without liability, under the following
					circumstances: (i) for scheduled or emergency maintenance to the Site
					or the Services, or any part thereof; (ii) if MUI believes that you
					are using the Site or Services in violation of these Terms or
					applicable law; (iii) if MUI believes that your use of the Site or the
					Services poses a security risk to us or to any third party; (iv) if
					required by law enforcement or government agency, or otherwise in
					order to comply with applicable law or regulation; or (v) if you fail
					to fulfill your payment obligations hereunder. MUI also reserves the
					right to temporarily or permanently suspend your ability to access the
					Services, without liability, if MUI determines, in its sole
					discretion, that you are engaging in abusive or excessively frequent
					use of the Services.
				</p>
			</li>
			<li>
				<p>
					12.3 Either of us can terminate these Terms upon notice to the other
					if the other party breaches any of these Terms and fails to cure the
					breach within fifteen (15) days of receiving written notice of it from
					the non-breaching party. We reserve the right to terminate these Terms
					for cause immediately upon notice to you, and without giving you a
					cure period, if you breach any of these Terms relating to our
					intellectual property (including your compliance with the access grant
					and any restrictions) or our Confidential Information (defined below).
				</p>
			</li>
			<li>
				<p>
					12.4 We can terminate any free Plan that you have subscribed to, at
					any time and for any reason, without notice or liability to you. We
					can terminate any paid Plan that you have subscribed to, for any
					reason and without liability, by providing notice to you that we
					intend to terminate your Plan at the end of the then-current
					Subscription Period.
				</p>
			</li>
			<li>
				<p>
					12.5 When these Terms terminate or expire: (i) you will no longer have
					the right to use or access the Site or the Services as of the date of
					termination/expiration; (ii) if you owed us any fees prior to such
					termination/expiration, you will pay those fees immediately; and (iii)
					each of us will promptly return to the other (or, if the other party
					requests it, destroy) all Confidential Information belonging to the
					other. Sections 1, 2, 4 through 10, 11, and 13 through 15 will survive
					the termination or expiration of these Terms for any reason.
				</p>
			</li>
		</ul>
		<h2 id='13-support'>13. Support</h2>
		<ul>
			<li>
				13.1 If you are subscribed to a paid Plan, MUI will provide you with
				email-based support – just write to our support desk at{' '}
				<a href='mailto:support@mui.com'>support@mui.com</a>. While we work hard
				to respond to you and resolve your issues quickly, we do not warrant
				that we will respond within any particular timeframe, or that we will be
				able to resolve your issue. If you are subscribed to a free Plan, while
				you are welcome to email us your questions, we encourage you to visit
				our community forum which can provide valuable information to help
				answer your questions.
			</li>
		</ul>
		<h2 id='14-confidential-information'>14. Confidential information</h2>
		<ul>
			<li>
				<p>
					14.1 For the purposes of these Terms, &quot;Confidential
					Information&quot; means any technical or business information
					disclosed by one party to the other that: (i) if disclosed in writing,
					is marked &quot;confidential&quot; or &quot;proprietary&quot; at the
					time of disclosure; (ii) if disclosed orally, is identified as
					confidential or proprietary at the time of such disclosure, and is
					summarized in a writing sent by the disclosing Party to the receiving
					Party within thirty (30) days of the disclosure. For the purposes of
					these Terms you agree that the Feedback, any Reports we provide to
					you, and any non-public elements of the Site or the Services
					(including, without limitation, the source code of any MUI-proprietary
					software), will be deemed to be MUI&#39;s Confidential Information,
					regardless of whether it is marked as such.
				</p>
			</li>
			<li>
				<p>
					14.2 Neither of us will use the other party&#39;s Confidential
					Information, except as permitted by these Terms. Each of us agrees to
					maintain in confidence and protect the other party&#39;s Confidential
					Information using at least the same degree of care as it uses for its
					own information of a similar nature, but in all events at least a
					reasonable degree of care. Each of us agrees to take all reasonable
					precautions to prevent any unauthorized disclosure of the other
					party&#39;s Confidential Information, including, without limitation,
					disclosing Confidential Information only to its employees, independent
					contractors, consultants, and legal and financial advisors
					(collectively, &quot;Representatives&quot;): (i) with a need to know
					such information, (ii) who are parties to appropriate agreements
					sufficient to comply with this Section 13, and (iii) who are informed
					of the nondisclosure obligations imposed by this Section 13. Each
					party will be responsible for all acts and omissions of its
					Representatives. The foregoing obligations will not restrict either
					party from disclosing Confidential Information of the other party
					pursuant to the order or requirement of a court, administrative
					agency, or other governmental body, provided that the party required
					to make such a disclosure gives reasonable notice to the other party
					to enable them to contest such order or requirement.
				</p>
			</li>
			<li>
				<p>
					14.3 The restrictions set forth in Section 13 will not apply with
					respect to any Confidential Information that: (i) was or becomes
					publicly known through no fault of the receiving party; (ii) was
					rightfully known or becomes rightfully known to the receiving party
					without confidential or proprietary restriction from a source other
					than the disclosing party who has a right to disclose it; (iii) is
					approved by the disclosing party for disclosure without restriction in
					a written document which is signed by a duly authorized officer of
					such disclosing party; or (iv) the receiving party independently
					develops without access to or use of the other party&#39;s
					Confidential Information.
				</p>
			</li>
		</ul>
		<h2 id='15-trademarks'>15. Trademarks</h2>
		<p>
			You acknowledge and agree that any MUI names, trademarks, service marks,
			logos, trade dress, or other branding included on the Site or as part of
			the Services (collectively, the &quot;Marks&quot;) are owned by MUI and
			may not be copied, imitated, or used (in whole or in part) without
			MUI&#39;s prior written consent. All other trademarks, names, or logos
			referenced on the Site or the Services (collectively, &quot;Third-Party
			Trademarks&quot;) are the property of their respective owners, and the use
			of such Third-Party Trademarks inure to the benefit of their respective
			owners. The use of such Third-Party Trademarks is intended to denote
			interoperability, and does not constitute an affiliation by MUI or its
			licensors with any company or an endorsement or approval by that company
			of MUI, its licensors, or their respective products or services.
		</p>
		<h2 id='16-general-provisions'>16. General provisions</h2>
		<ul>
			<li>
				<p>
					16.1 These Terms, together with any policies incorporated into these
					Terms by reference, are the complete and exclusive understanding of
					the parties with respect to MUI&#39;s provision of, and your use of
					and access to, the Site and the Services, and supersede all previous
					or contemporaneous agreements or communications, whether written or
					oral, relating to the subject matter of these Terms (including,
					without limitation, prior versions of these Terms). Any terms or
					conditions that you send to MUI that are inconsistent with or in
					addition to these Terms are hereby rejected by MUI, and will be deemed
					void and of no effect.
				</p>
			</li>
			<li>
				<p>
					16.2 These Terms will be governed by and construed in accordance with
					the laws of the State of California, without regard to that
					State&#39;s conflict of law principles. Any legal action or proceeding
					arising under, related to or connected with these Terms will be
					brought exclusively in the federal (if they have jurisdiction) or
					state courts located in San Francisco, California, and the parties
					irrevocably consent to the personal jurisdiction and venue of such
					court(s). The United Nations Convention on Contracts for the
					International Sale of Goods and the Uniform Computer Information
					Transactions Act will not apply to these Terms. If a party initiates
					any proceeding regarding these Terms, the prevailing party to such
					proceeding is entitled to reasonable attorneys&#39; fees and costs.
				</p>
			</li>
			<li>
				<p>
					16.3 You agree that MUI has the right to use your name and logo on the
					Site or other MUI websites or marketing materials, for the purposes of
					identifying you as a MUI customer and describing your use of the
					Services. You also agree that MUI may (but is under no obligation to):
					(i) issue a press release identifying you as a MUI customer; (ii)
					inform other potential customers that you are a user of the Services;
					and (iii) identify you as a customer in other forms of publicity
					(including, without limitation, case studies, blog posts, and the
					like.
				</p>
			</li>
			<li>
				<p>
					16.4 You may not assign these Terms, in whole or in part, by operation
					of law or otherwise, without the prior written consent of MUI, and any
					attempted transfer, assignment or delegation without such consent will
					be void and of no effect. MUI may freely transfer, assign or delegate
					these Terms, or its rights and duties under these Terms, without
					notice to you. Subject to the foregoing, these Terms will be binding
					upon and will inure to the benefit of the parties and their respective
					representatives, heirs, administrators, successors and permitted
					assigns.
				</p>
			</li>
			<li>
				<p>
					16.5 Except as expressly set forth in these Terms, the exercise by
					either party of any of its remedies will be without prejudice to its
					other remedies under these Terms or otherwise. The failure by a party
					to enforce any part of these Terms will not constitute a waiver of
					future enforcement of that or any other provision. Any waiver of any
					provision of these Terms will be effective only if in writing and
					signed by an authorized representative of the waiving party.
				</p>
			</li>
			<li>
				<p>
					16.6 You agree that any notice that MUI is required to provide
					pursuant to these Terms can be given electronically, which may include
					an email to the email address you provide to MUI as part of your
					Registration Data. These notices can be about a wide variety of
					things, including responding to your questions, requests for
					additional information, and legal notices. You agree that such
					electronic notices satisfy any legal requirement that such
					communications be in writing. An electronic notice will be deemed to
					have been received on the day the email is sent to you, provided that
					the email is the same as the email address you provided as part of
					your Registration Data.
				</p>
			</li>
			<li>
				<p>
					16.7 You acknowledge that you are responsible for complying with all
					applicable laws and regulations associated with your access and use of
					the Site and Services, including, without limitation, all applicable
					export control laws and regulations.
				</p>
			</li>
			<li>
				<p>
					16.8 We do not develop any technical data or computer software
					pursuant to these Terms. The Site and the Services have been developed
					solely with private funds, are considered &quot;Commercial Computer
					Software&quot; and &quot;Commercial Computer Software
					Documentation&quot; as described in FAR 12.212, FAR 27.405-3, and
					DFARS 227.7202-3, and access is provided to U.S. Government end users
					as restricted computer software and limited rights data. Any use,
					disclosure, modification, distribution, or reproduction of the Site or
					the Services by the U.S. Government, its end users or contractors is
					subject to the restrictions set forth in these Terms.
				</p>
			</li>
			<li>
				<p>
					16.9 If any portion of these Terms is held to be unenforceable or
					invalid, that portion will be enforced to the maximum extent possible,
					and all other provisions will remain in full force and effect.
				</p>
			</li>
			<li>
				<p>
					16.10 Except for payments due under these Terms, neither party will be
					responsible for any delay or failure to perform that is attributable
					in whole or in part to any cause beyond its reasonable control,
					including, without limitation, acts of God (fire, storm, floods,
					earthquakes, etc.); civil disturbances; disruption of
					telecommunications, power or other essential services; interruption or
					termination of service by any service providers used by MUI to host
					the Services or to link its servers to the Internet; labor
					disturbances; vandalism; cable cut; computer viruses or other similar
					occurrences; or any malicious or unlawful acts of any third party.
				</p>
			</li>
			<li>
				<p>
					16.11 We are each independent contractors with respect to the subject
					matter of these Terms. Nothing contained in these Terms will be deemed
					or construed in any manner whatsoever to create a partnership, joint
					venture, employment, agency, fiduciary, or other similar relationship
					between us, and neither of us can bind the other contractually.
				</p>
			</li>
		</ul>
	</div>
);
