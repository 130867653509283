/** @format */

import * as React from 'react';
import { Box, Button, Container } from '@mui/material';
import { Typography } from '../../../../components';

export const ProductSmokingHero = () => {
	return (
		<Container
			component='section'
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				my: 9,
			}}
		>
			<Button
				sx={{
					border: '4px solid currentColor',
					borderRadius: 0,
					height: 'auto',
					py: 2,
					px: 5,
				}}
			>
				<Typography variant='h4' component='span'>
					Got any questions? Need help?
				</Typography>
			</Button>
			<Typography variant='subtitle1' sx={{ my: 3 }}>
				We are here to help. Get in touch!
			</Typography>
			<Box
				component='img'
				src='/static/themes/producBuoy.svg'
				alt='buoy'
				sx={{ width: 60 }}
			/>
		</Container>
	);
};
