/** @format */

export const PrivacyContent = () => (
	<div>
		<p>Last modified: October 7th, 2018.</p>
		<p>
			MUI is committed to protecting and respecting your privacy. This Privacy
			Policy sets out how we collect and process personal information about you
			when you visit the website mui.com, when you use our products and services
			(our &quot;Services&quot;), or when you otherwise do business or make
			contact with us.
		</p>
		<p>
			Please read this policy carefully to understand how we handle and treat
			your personal information.
		</p>
		<h2 id='what-information-do-we-collect-'>
			What information do we collect?
		</h2>
		<p>
			We may collect and process the following personal information from you:
		</p>
		<ul>
			<li>
				<strong>Information you provide to us:</strong> We collect personal
				information when you voluntarily provide us with such information in the
				course of using our website or Services. For example, when you register
				to use our Services, we will collect your name, email address and
				organization information. We also collect personal information from you
				when you subscribe to our newsletter, or respond to a survey. If you
				make an enquiry through our website, or contact us in any other way, we
				will keep a copy of your communications with us.
			</li>
			<li>
				<strong>Information we collect when you do business with us:</strong> We
				may process your personal information when you do business with us – for
				example, as a customer or prospective customer, or as a vendor,
				supplier, consultant or other third party. For example, we may hold your
				business contact information and financial account information (if any)
				and other communications you have with us for the purposes of
				maintaining our business relations with you.
			</li>
			<li>
				<strong>Information we automatically collect:</strong> We may also
				collect certain technical information by automatic means when you visit
				our website, such as IP address, browser type and operating system,
				referring URLs, your use of our website, and other clickstream data. We
				collect this information automatically through the use of various
				technologies, such as cookies.
			</li>
			<li>
				<strong>Personal information where we act as a data processor:</strong>{' '}
				We also process personal information on behalf of our customers in the
				context of supporting our products and services. Where a customer
				subscribes to our Services for their website, game or app, they will be
				the ones who control what event data is collected and stored on our
				systems. For example, they may ask us to log basic user data (e.g. email
				address or username), device identifiers, IP addresses, event type, and
				related source code. In such cases, we are data processors acting in
				accordance with the instructions of our customers. You will need to
				refer to the privacy policies of our customers to find out more about
				how such information is handled by them.
			</li>
		</ul>
		<h2 id='what-do-we-use-your-information-for-'>
			What do we use your information for?
		</h2>
		<p>
			The personal information we collect from you may be used in one of the
			following ways:
		</p>
		<ul>
			<li>To deal with your inquiries and requests</li>
			<li>
				To create and administer records about any online account that you
				register with us
			</li>
			<li>
				To provide you with information and access to resources that you have
				requested from us
			</li>
			<li>
				To provide you with technical support (your information helps us to
				better respond to your individual needs)
			</li>
			<li>
				To improve our website (we continually strive to improve our website
				offerings based on the information and feedback we receive from you),
				including to improve the navigation and content of our sites
			</li>
			<li>For website and system administration and security</li>
			<li>
				For general business purposes, including to improve customer service
				(your information helps us to more effectively respond to your customer
				service requests and support needs), to help us improve the content and
				functionality of our Services, to better understand our users, to
				protect against wrongdoing, to enforce our Terms of Service, and to
				generally manage our business
			</li>
			<li>
				To process transactions and to provide Services to our customers and
				end-users
			</li>
			<li>For recruitment purposes, where you apply for a job with us</li>
			<li>
				To administer a contest, promotion, survey, or other site features
			</li>
			<li>
				To send periodic emails. The email address you provide for order
				processing, will only be used to send you information and updates
				pertaining to your order. Where it is in accordance with your marketing
				preferences, we will send occasional marketing emails about our products
				and services, which you can unsubscribe from at any time using the link
				provided in the message.
			</li>
		</ul>
		<h2 id='how-do-we-protect-your-information-'>
			How do we protect your information?
		</h2>
		<p>
			We implement a variety of security measures to maintain the safety of your
			personal information when you enter, submit, or access your personal
			information. We offer the use of a secure server. All supplied
			sensitive/credit information is transmitted via Secure Socket Layer (SSL)
			technology and then encrypted into our Payment gateway providers database
			only to be accessible by those authorized with special access rights to
			such systems, and are required to keep the information confidential. After
			a transaction, your private information (credit cards, social security
			numbers, financials, etc.) will not be stored on our servers.
		</p>
		<h2 id='do-we-use-cookies-'>Do we use cookies?</h2>
		<p>
			Yes. Cookies are small files that a site or its service provider transfers
			to your computers hard drive through your Web browser (if you allow) that
			enables the sites or service providers systems to recognize your browser
			and capture and remember certain information.
		</p>
		<p>
			We use cookies to understand and save your preferences for future visits,
			to advertise to you on other sites and compile aggregate data about site
			traffic and site interaction so that we can offer better site experiences
			and tools in the future.
		</p>
		<p>
			You may refuse to accept cookies by activating the setting on your browser
			which allows you to refuse the setting of cookies. You can find
			information on popular browsers and how to adjust your cookie preferences
			at the following websites:
		</p>
		<ul>
			<li>Microsoft Internet Explorer</li>
			<li>Mozilla Firefox</li>
			<li>Google Chrome</li>
			<li>Apple Safari</li>
		</ul>
		<p>
			However, if you choose to disable cookies, you may be unable to access
			certain parts of our site. Unless you have adjusted your browser setting
			so that it will refuse cookies, our system will issue cookies when you log
			on to our site.
		</p>
		<h2 id='do-we-disclose-any-information-to-outside-parties-'>
			Do we disclose any information to outside parties?
		</h2>
		<p>
			We will only share your information with third parties in certain
			circumstances:
		</p>
		<ul>
			<li>
				We engage certain trusted third parties to perform functions and provide
				services to us, including cloud hosting services, off-site backups,
				email service providers, and customer support providers. We will only
				share your personal information with third parties to the extent
				necessary to perform these functions, in accordance with the purposes
				set out in this Privacy Policy and applicable laws.
			</li>
			<li>
				In the event of a corporate sale, merger, reorganization, dissolution or
				similar event, your personal information may be sold, disposed of,
				transferred or otherwise disclosed as part of that transaction.
			</li>
			<li>
				We may also disclose information about you to third parties where we
				believe it necessary or appropriate under law, for example: (1) to
				protect or defend our rights, interests or property or that of third
				parties; (2) to comply with legal process, judicial orders or subpoenas;
				(3) to respond to requests from public or government authorities,
				including for national security and law enforcement purposes; (4) to
				prevent or investigate possible wrongdoing in connection with the
				Services or to enforce our Terms of Service; (5) to protect the vital
				interests of our users, customers and other third parties.
			</li>
			<li>
				We may use and share aggregated non-personal information with third
				parties for marketing, advertising and analytics purposes.
			</li>
		</ul>
		<p>We do not sell or trade your personal information to third parties.</p>
		<h2 id='third-party-links'>Third Party Links</h2>
		<p>
			Occasionally, at our discretion, we may include or offer third party
			products or services on our website. If you access other websites using
			the links provided, the operators of these websites may collect
			information from you that will be used by them in accordance with their
			privacy policies. These third party sites have separate and independent
			privacy policies. We therefore have no responsibility or liability for the
			content and activities of these linked sites. Nonetheless, we seek to
			protect the integrity of our site and welcome any feedback about these
			sites.
		</p>
		<h2 id='international-transfers'>International Transfers</h2>
		<p>
			If you are visiting our website or using our Services from outside the
			United States (US), please be aware that you are sending personal
			information to the US where our servers are located. The US may not have
			data protection laws that are as comprehensive or protective as those in
			your country of residence; however, our collection, storage and use of
			your personal information will at all times be in accordance with this
			Privacy Policy.
		</p>
		<h2 id='your-rights'>Your Rights</h2>
		<p>
			If you are from the EU, you may have the right to access a copy of the
			personal information we hold about you, or to request the correction,
			amendment or deletion of such information where it is inaccurate or
			processed in violation of the Privacy Shield Principles. To make such a
			request, please contact us at the contact details at the left.
		</p>
		<p>
			We will consider and respond to your request in accordance with the
			Privacy Shield Principles and applicable laws.
		</p>
		<p>
			Furthermore, we commit to giving you an opportunity to opt-out if your
			personal information is to be disclosed to any other independent third
			parties, or to be used for a purpose materially different from those that
			are set out in this Privacy Policy. Where sensitive personal information
			is involved, we will always obtain your express opt-in consent to do such
			things. If you otherwise wish to limit the use or disclosure of your
			personal information, please write to us at the contact details further
			below.
		</p>
		<p>
			You can also unsubscribe from our marketing communications at any time by
			following the instructions or unsubscribe mechanism in the email message
			itself.
		</p>
		<h2 id='data-retention'>Data Retention</h2>
		<p>
			We may retain your personal information as long as you continue to use the
			Services, have an account with us or for as long as is necessary to fulfil
			the purposes outlined in the policy. You can ask to close your account by
			contacting us at the details below and we will delete your personal
			information on request.
		</p>
		<p>
			We may however retain personal information for an additional period as is
			permitted or required under applicable laws, for legal, tax or regulatory
			reasons, or for legitimate and lawful business purposes.
		</p>
		<h2 id='changes-to-our-privacy-policy'>Changes to our Privacy Policy</h2>
		<p>
			If we decide to change our privacy policy, we will post those changes on
			this page, and/or update the Privacy Policy modification date below.
		</p>
	</div>
);
